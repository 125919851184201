#brainhearing {
    padding-bottom: 0;
    padding-top: 0;
    .two--col {
        @media (min-width: $mobile) {
            display: block; }
        @media (min-width: $tablet) {
            display: grid; } }
    // small
    //     margin-top: 3rem
    //     display: block
    .align--center {
        align-items: stretch; }
    .left--content {
        .container {
            @media (min-width: $tablet) {
                padding: 6rem 2rem; } } }
    .right--content {
        position: relative;
        padding: 2rem;
        padding-bottom: 0;
        img {
            max-width: 60vw;
            margin: 0 auto; }
        @media (min-width: $tablet) {
            padding: 0;
            img {
                max-width: 100%;
                margin: 0;
                position: absolute;
                left: 0;
                bottom: 0; } } } }
#more {
    padding: 2rem;
    background-color: #fff;
    @media (min-width: $tablet) {
        padding: 5rem 2rem; }
    .mobile--grafik {
        width: 30rem;
        margin: 2rem auto;
        @media (min-width: 300px) {
            display: none; } }
    .tablet--grafik {
        display: none;
        width: 50rem;
        margin: 2rem 0;
        @media (min-width: 300px) {
            display: block; }
        @media (min-width: $desktop) {
            display: none; } }
    .desktop--grafik {
        display: none;
        @media (min-width: $desktop) {
            display: block; } } }





#hoersysteme {
    .desktop {
        display: none;
        width: 100%; }
    .two--col {
        display: block; }
    @media (min-width: $tablet) {
        .desktop {
            display: none; }
        .two--col {
            display: block; } }
    @media (min-width: $desktop) {
        .two--col {
            display: grid;

            align-items: center; } }
    .neural {
        background: url('../img/ot-neural.jpg') right center no-repeat;
        background-size: cover;
        min-height: 50vw;
        @media (min-width: $desktop) {
            .desktop {
                display: block; }

            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
 } }            // min-height: 685px
    .icons {
        padding: 2rem;
        h4 {
            margin-bottom: 4rem; }
        .main--button {
            margin-top: 3rem; } } }

#akku {
    padding: 5rem 2rem;
    // padding-bottom: 4rem
    h4 {
        max-width: 50rem;
        font-family: $regular;
        &.mt {
            max-width: 100%;
            margin-top: 6rem; } }
    #streaming {
        margin-top: 2rem; }
    strong {
        display: block; }
    #more--geraet {
        margin: 2rem auto; }
    #more--akku {
        margin: 2rem auto; }
    .farbpalette {
        margin: 4rem 0 2rem;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .geraet {
            flex: 1 0 calc(100% / 2 - 2rem);
            -ms-flex: 1 0 45%;
            text-align: center;
            margin-bottom: 2rem;
            @media (min-width: $mobile) {
                flex: 1 0 calc(100% / 4 - 4rem);
                -ms-flex: 1 0 18%; }
            @media (min-width: $desktop) {
                flex: 1 0 calc(100% / 8 - 2rem);
                -ms-flex: 1 0 10%; }
            p {
                position: relative;
                transform: translateX(-12%); }
            strong {
                margin-top: 2rem;
                text-transform: uppercase; }

            margin-right: 2rem;
            &:nth-child(2n) {
                margin-right: 0; }
            @media (min-width: $mobile) {
                margin-right: 4rem!important;
                &:nth-child(4n) {
                    margin-right: 0rem!important; } }
            @media (min-width: $desktop) {
                margin-right: 2rem!important;
                &:nth-child(4n) {
                    margin-right: 2rem!important; }
                &:last-child {
                    margin-right: 0!important; } } } } }
.form-submission {
    max-width: 80rem;
    margin: 3rem auto;
    background: white;
    padding: 2rem;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 3rem;
        height: 3rem;
        background: white;
        transform: translate(-50%,-50%) rotate(45deg); }
    h3 {
        font-size: 2.5rem;
        text-align: center;
        margin: 0; } }
.form-error {
    padding: 1rem;
    // border: 1px solid $magenta
    background: $magenta;
    color: white;
    text-align: center;
    margin-bottom: 1rem; }

.video--content {
    background: url('../img/vorhang-4k-blurry.jpg') center no-repeat;
    background-size: cover;
    @media (min-width: 1400px) {
        padding: 4rem 0; }
    > .content {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        box-shadow: 0 0 1rem rgba(black, 0.4); } }

.stickies {
    position: fixed;
    bottom: 20px;
    right: 100px;
    display: flex;
    align-items: center;
    .sticky {
        box-shadow: 0 0 1rem rgba(black, 0.2);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;
        &.hd {
            background: $magenta;
            color: white;
            &:before {
                content: "HD";
                font-size: 2rem;
                margin: 0;
                color: white; }
            h3 {
                font-size: 2rem;
                margin: 0;
                font-weight: 400; }
            p {
                position: absolute;
                bottom: 120%;
                right: 0;
                font-size: 1.2rem;
                width: 220px;
                background: white;
                padding: 1rem;
                box-shadow: 0 0 1rem rgba(black, 0.2);
                transform: translateY(20%);
                opacity: 0;
                pointer-events: none;
                display: block;
                transition: 300ms ease;
                &:before {
                    content: "";
                    position: absolute;
                    width: 1.5rem;
                    height: 1.5rem;
                    bottom: -1rem;
                    left: 50%;
                    background: white;
                    display: block;
                    transform: rotate(45deg) translate(-50%,0%); } }
            &.active {
                &:before {
                    content: "\d7";
                    font-size: 3rem; }
                p {
                    opacity: 1;
                    transform: translateY(0%); } } }
        &.feedback {
            background: white;
            img {
                width: 50%; } } }
    @media (max-width: 640px) {
        right: 85px;
        .sticky {
            width: 48px;
            height: 48px; } } }
